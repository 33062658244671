@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
    font-family: 'Inter', sans-serif;
}

img {
    user-select: none;
}

body {
    margin: 0;
    min-height: 100vh;
    background-color: #aee0ff;
    position: relative;
}

div {
    box-sizing: border-box;
}

.full-width {
    width: 100%;
}

.nav {
    background-color: #fff;
    display: flex;
    align-items: center;

    img {
        margin-left: 27px;
        height: 80px;
        width: 80px;
    }

    @media screen and (max-width: 900px) {
        img {
            margin-left: 16px;
            height: 58px;
            width: 58px;
        }
    }
}

.main-content {
    background-color: #aee0ff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px 0;

    .main-card {
        background-color: #fff;
        width: 92%;
        max-width: 821px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 0;
        border-radius: 16px;
        box-shadow: 12px 12px 24px 0 rgba(0, 156, 222, 0.25);
        position: relative;

        .back-button {
            position: absolute;
            left: 24px;
            top: 24px;
            cursor: pointer;
        }

        .guest-pass-img {
            width: 118px;
            height: 164px;
        }

        .title {
            font-size: 20px;
            color: rgb(0, 59, 92);
            text-align: center;
        }

        .sub-title {
            max-width: 454px;
            text-align: center;
            padding: 0 16px;
        }

        &__header {
            display: flex;
            flex-direction: column;
            align-items: center;

            .title--community {
                margin-bottom: 0;
            }
        }

        .help-link {
            font-size: 12px;
            color: rgb(0, 156, 222);
            text-decoration: underline;
            cursor: pointer;
        }

        .powered-by-myq-img {
            margin: 3px 0 12px;
        }
    }

  &--commercial {
    .main-card {
      .guest-pass-img {
        height: auto;
        width: auto;
        margin-bottom: 8px;
      }

      .entry-code-text,
      .sub-title {
        max-width: 500px;
      }

      .active-period-info {
        p {
          margin-bottom: 5px;
          margin-top: 5px;
        }

        &.detail-container > img {
          padding-top: 4px !important;
        }
      }
    }
  }
}

.text-centered {
    text-align: center;
}

.mat-dialog-container {
    position: relative;
    padding: 0 !important;
    overflow: hidden !important;

    .dialog {
        display: flex;
        flex-flow: column;
        height: 100%;
        padding: 34px 34px 24px 34px;
        gap: 8px;

        .image {
            display: flex;
            justify-content: center;
            margin-top: -8px;
        }

        .mat-dialog-title {
            margin: 0;
            font-size: 16px;
            font-weight: bold;
            color: #191919;
            line-height: normal;

            &.center {
                text-align: center;
            }
        }

        .mat-dialog-content {
            font-size: 14px;
        }

        .mat-dialog-actions {
            padding: 0;
            margin-bottom: 0;
            bottom: 24px;
            margin-top: auto;
        }

        .close {
            position: absolute;
            top: 14px;
            right: 14px;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            span {
                font-weight: 500;
                font-size: 24px;
            }
        }
    }
}

.pass-status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;

    &.expired {
        background-color: #fff7e2;
        color: #d93900;
    }

    &.active {
        background-color: #defaed;
        color: #2faa4e;
    }

    &.not-yet-active {
        background-color: #edf9ff;
        font-weight: normal;
        text-align: center;

        .active-date-text {
            color: #009cde;
            font-weight: bold;
        }
    }
}

button {
    width: 88px;
    height: 48px;
    border-radius: 8px !important;
    border: solid 1px #003b5c !important;
    box-shadow: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;

    &.btn-primary {
        width: 95px;
        background-color: #003b5c;
        color: #fff;
    }

    &.btn-secondary, &.btn-secondary:active {
        background-color: #fff !important;
        color: #003b5c;
    }
}

.mat-button-focus-overlay {
    background-color: transparent !important;
}

footer {
    text-align: center;
    height: 36px;
}
